import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default {
	state: {
		getStarted: false,
		allSettings: {},
	},
	mutations: {
		SET_SETTINGS: (state, data) => {
			let temp = data
			if (data.userApp == null) {
				temp.userApp = {
					options: {
						seenIntro: false,
					}
				}
				state.allSettings = temp
			} else {
				state.allSettings = data
			}
		},
		SET_APPDATA(state, { users = [] }) {
			// TODO:
		},
		GET_STARTED: state => {
			state.getStarted = true
		},
	},
	actions: {
		SET_APPDATA: ({ commit }, data) => commit('SET_APPDATA', data),
	},
	getters: {
		isWorksheetActive(state, getters, rootState, rootGetters) {
			return rootGetters.hasWorksheetAppAccess && (state.allSettings?.instance?.options?.activeFeatures?.worksheet ?? true);
		}
	}
}
