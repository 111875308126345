<template>
	<SubBarGlobal :currentCrumb="crumbName">
		<template v-if="$route.path == '/'">
			<div class="mr-4 d-none d-xl-block dash-divider" />
		</template>
		<PageButtons />

	</SubBarGlobal>
</template>
<script>
import SubBarGlobal from 'bh-mod/components/layout/SubBarGlobal'
import PageButtons from 'bh-mod/components/layout/SubBarGlobal/PageButtons'

export default {
	components: { SubBarGlobal, PageButtons },
	data() {
		return {

		}
	},
	computed: {
		crumbName() {
			if (this.$route.meta.title) {
				return this.$route.meta.title
			} else {
				return 'Users'
			}
		},
	},
	methods: {
	},
}
</script>

<style lang="scss">
.ant-form-horizontal.search-horizontal {
	label {}

	.ant-form-item-control-wrapper {
		flex: 1;
	}

	.ant-form-item {
		display: flex;
	}

	.ant-form-item-label {
		min-width: 100px;
	}
}
</style>
