<template>
	<div id="app">
		<RoleDrawer />
		<MediaSelector />
		<localization></localization>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import MediaSelector from 'bh-mod/components/file/MediaSelector'
import RoleDrawer from '@/components/RoleDrawer'

export default {
	name: 'app',
	components: { Localization, MediaSelector, RoleDrawer },
	computed: {
		...mapGetters(['user']),
	},
	watch: {
		'$route'(to, from) {
			const query = Object.assign({}, to.query)
			this.$store.commit('SETUP_URL_SETTINGS', query)
		},
	},
	methods: {

	}
}
</script>
<style lang='scss'>
#app {
	min-height: 100vh;
}

.role-overlay {
	.ant-drawer-content-wrapper {
		width: 100% !important;
		max-width: 800px;
	}
}
</style>
