<template>
	<div style="height: 100%">
		<LeftNavBar :title="selectedAppInitial" :accordion="true" @optionclick="optionClicked" class="f1"
			:items="navItems" :hasChild="true" @accordion="onRoleSelect" :selectedValue="selectedRole"
			@opened="parentClicked" :selectedParent="selectedParent" />
	</div>
</template>

<script>
	import LeftNavBar from "bh-mod/components/common/LeftNavBar";

	export default {
		components: { LeftNavBar },
		name: "menu-left",
		data() {
			return {
				openID: "",
				selectedParent: "roles"
			};
		},
		computed: {
			selectedAppInitial() {
				return (
					(this.$store.state.theApp &&
						this.$store.state.theApp.media &&
						this.$store.state.theApp.media.initial) ||
					"TC"
				);
			},
			navItems() {
				let roles = this.order(this.roles);

				const totalUsers = Object.values(this.roleCounts).reduce((previousSum, nextValue) => {
					return previousSum + nextValue
				}, 0)

				let rolesAccordion = {
					label: "Roles",
					clicked: () => { },
					showAccordion: true,
					show: true,
					value: "roles",
					children: [{
						label: `All (${totalUsers})`,
						value: null,
						iconUrl: require("@/assets/sideIcons/team.svg"),
						options: []
					}],
				};

				roles.forEach((role) => {
					let child = {
						label: "",
						value: "",
						options: [],
						iconUrl: ""
					};
					if (this.$p >= 40) {
						child.options = [
							{
								label: "Edit Role",
								value: "edit",
							},
							{
								label: "Delete Role",
								value: "delete",
							},
						]
					}
					if (role.name == "bh_admin") {
						child.label = `Admin (${this.roleCounts[role.id] || 0})`;
						child.options = [];
						child.value = role.id;
					} else if (role.name == "bh_agent") {
						child.label = `VIP Realtor (${this.roleCounts[role.id] || 0})`;
						child.options = [];
						child.value = role.id;
					} else if (role.name == "bh_agent_manager") {
						child.label = `VIP Broker Manager (${this.roleCounts[role.id] || 0})`;
						// Only admin should able to edit VIP Broker manager role
						if (this.$p >= 50) {
							child.options = [
								{
									label: "Edit Role",
									value: "edit",
								}
							];
						} else {
							child.options = [];
						}

						child.value = role.id;
					} else {
						child.label = `${role.name} (${this.roleCounts[role.id] || 0
							})`;
						child.value = role.id;
					}

					rolesAccordion.children.push(child);
				});

				return [
					rolesAccordion
				];
			},
			roles() {
				return this.$store.state.roles;
			},
			roleCounts() {
				return this.$store.state.roleCounts;
			},
			selectedRole() {
				return this.$store.state.selectedRole;
			},
		},

		created() {
			if (this.$route.path.includes('/settings')) {
				this.selectedParent = 'settings'
			}
		},

		methods: {
			optionClicked(option) {
				if (option.optionValue == "edit") {
					this.$store.commit("UPDATE_EDIT_ROLE_NUM", option.itemValue);
				} else if (option.optionValue == "delete") {
					this.$store.commit("UPDATE_DELETE_ROLE_NUM", option.itemValue);
				}
			},

			order(roles) {
				let orderArray = [];
				let admin = null;
				let manager = null;
				let realtor = null;
				roles.forEach((x) => {
					if (x.name === "bh_admin") {
						admin = x;
					} else if (x.name === "bh_agent_manager") {
						manager = x;
					} else if (x.name === "bh_agent") {
						realtor = x;
					} else {
						orderArray.push(x);
					}
				});
				orderArray.sort((a, b) => {
					if (a.name > b.name) return 1;
					if (a.name < b.name) return -1;
					else return 0;
				});
				let list = [];
				if (admin) {
					list.push(admin);
				}
				if (manager) {
					list.push(manager);
				}
				if (realtor) {
					list.push(realtor);
				}

				orderArray.forEach((x) => {
					list.push(x);
				});
				return list;
			},

			onRoleSelect(role) {
				this.$store.commit("UPDATE_SELECTED_ROLE", role.value === this.selectedRole ? null : role.value);
			},

			parentClicked(item) {
				this.selectedParent = item.value;
				if (item.value === 'settings' && this.$route.path !== '/settings') {
					this.$router.push('/settings');
					this.$store.commit("UPDATE_SELECTED_ROLE", undefined);
				} else if (this.$route.path !== '/') {
					this.$router.push('/')
				}
			}
		},
	};
</script>
